import transactionsService from "../../../../services/transactionsService";
import $statusDictionary from "../../../../mixins/statusDictionary";
import $formatNumber from "../../../../mixins/formatNumber";

export default {
  name: "corrupted-payment",
  components: {},
  props: [],
  mixins: [$statusDictionary, $formatNumber],
  data() {
    return {
      corruptedPayment: [],
      statuses: "",
      errors: "",
      refIdSearch: null,
      orderIdSearch: null,
      statusText: "همه",
      status: null,
      typeText: "همه",
      type: null,
      currentPage: 1,
      tableHeaders: [
        "نام شرکت",
        "مقدار لازم",
        "مقدار پرداخت شده",
        "کارمزد عملیات",
        "آدرس مشتری",
        "علت خطا",
        "کد پیگیری بیت‌راه",
        "کد پیگیری فروشگاه",
        "وضعیت",
        "عملیات"
      ]
    };
  },
  computed: {},
  mounted() {
    this.getCorruptedFunc();
    this.getStatusFunc();
    this.getErrorFunc();
  },
  methods: {
    changeStatus(status, text) {
      this.status = status;
      this.statusText = text;
    },
    changeType(type, text) {
      this.type = type;
      this.typeText = text;
    },
    submitFilterCorruptedPayment() {
      if (this.refIdSearch === "") {
        this.refIdSearch = null;
      }
      if (this.orderIdSearch === "") {
        this.orderIdSearch = null;
      }
      transactionsService
        .getCorruptedPayment(
          0,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type,
          this.refIdSearch,
          this.orderIdSearch
        )
        .then(response => {
          this.currentPage = 1;
          this.corruptedPayment = response.data;
          this.MappedCorruptedPayment();
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    getStatusFunc() {
      transactionsService.getCorruptedStatus().then(response => {
        this.statuses = response.data;
      });
    },
    getErrorFunc() {
      transactionsService.getErrors().then(response => {
        this.errors = response.data;
      });
    },
    getCorruptedFunc() {
      transactionsService
        .getCorruptedPayment(
          0,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type,
          this.refIdSearch,
          this.orderIdSearch
        )
        .then(response => {
          this.corruptedPayment = response.data;
          this.MappedCorruptedPayment();
        });
    },
    getPaginationData(id) {
      transactionsService
        .getCorruptedPayment(
          id - 1,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type,
          this.refIdSearch,
          this.orderIdSearch
        )
        .then(response => {
          this.corruptedPayment = response.data;
          this.MappedCorruptedPayment();
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    evacuate(data, index) {
      transactionsService
        .evacuateCorruptedPayment(
          data,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(response => {
          if (response.data) {
            this.corruptedPayment.content.splice(index, 1);
            this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
              title: this.$i18n.t("toast.successTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "successFontColor rtl text-right font-weight-bold py-3",
              headerClass:
                "successBackgroundColor successFontColor  rtl text-right",
              solid: false
            });
          } else {
            this.getCorruptedFunc();
            this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          }
        });
    },
    refund(data, index) {
      transactionsService
        .refundCorruptedPayment(
          data,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(response => {
          if (response.data) {
            this.corruptedPayment.content.splice(index, 1);
            this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
              title: this.$i18n.t("toast.successTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "successFontColor rtl text-right font-weight-bold py-3",
              headerClass:
                "successBackgroundColor successFontColor  rtl text-right",
              solid: false
            });
          } else {
            this.getCorruptedFunc();
            this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          }
        });
    },
    MappedCorruptedPayment() {
      this.corruptedPayment.content.forEach(elem => {
        Object.keys(this.statuses).forEach(key => {
          if (elem.order.status === key) {
            elem.color = this.statuses[key].color;
            elem.message = this.statuses[key].message;
          }
        });
      });
    }
  }
};
